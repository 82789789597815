<div class="row">
  <div class="col-12">
    <h2>
      {{'modules.valet.valet_documents.documents_to_verify' |translate}}
    </h2>
  </div>
  <div class="col-12">
    <common-card>
      <common-card-header>
        <div class="row no-gutters">
          <div class="col">
            <h6>
              {{'modules.valet.valet_documents.document_progression' |translate}}
            </h6>
          </div>
        </div>
      </common-card-header>
      <common-card-body>
        <nb-progress-bar [value]="documentsProgress"
                         [displayValue]="true"
                         size="giant"
                         status="success">
        </nb-progress-bar>
      </common-card-body>
    </common-card>
  </div>
  <div *ngIf="valetBlock && viewAs === USER_ROLE.ADMIN" class="col-12 mt-4">
    <nb-alert outline="danger">
      <div class="d-flex align-items-center">
        <div class="mr-3">
          <nb-icon icon="exclamation-triangle" status="danger" style="font-size:2rem;"></nb-icon>
        </div>
        <span>
          {{'modules.valet.valet_documents.valet_block.is_blocked' | translate}}
          <strong>{{valetBlock.firstName}} {{valetBlock.lastName}}</strong>
          ({{valetBlock.birthDate | momentPipe: 'DD/MM/YYYY'}}),
          {{'modules.valet.valet_documents.valet_block.added_at' | translate}}
          {{valetBlock.creationDate | momentPipe: 'DD/MM/YYYY à HH:mm'}}
        </span>
      </div>
    </nb-alert>
  </div>
  <div *ngIf="unavailableIdDocumentCheck && viewAs === USER_ROLE.ADMIN" class="col-12 mt-4">
    <nb-alert outline="danger">
      <div class="d-flex align-items-center">
        <div class="mr-3">
          <nb-icon icon="times" status="danger" style="font-size:2rem;"></nb-icon>
        </div>
        <span>
          {{'modules.valet.valet_documents.valet_block.unavailable_id_document_check' | translate}}
        </span>
      </div>
    </nb-alert>
  </div>
  <div class="col-12">
    <div class="row">
      <ng-container *ngFor="let document of allDocuments">
        <div class="col-md-4" *ngIf="!document.disabled">
          <pv-admin-valet-form-document
            [viewAs]="viewAs"
            [cardHeader]="document.title"
            [documentType]="document.key"
            [document]="documents? documents[document.key]: null"
            [ifApplicable]="document.ifApplicable || false"
            [optional]="document.optional || false"
            [requireExpiryDate]="document.requireExpiryDate || false"
            [rejectedReasons]="document.rejectedReasons || []"
            [isSubmitting]="documents?documents[document.key]?.isSubmitting: true"
            (documentChanged)="documentUpdateHandler($event)"
          >
          </pv-admin-valet-form-document>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col-12" *ngIf="!hideContract">
    <h2>
      {{'modules.valet.valet_documents.contract_and_activity' |translate}}
    </h2>
  </div>
  <div class="col-12" *ngIf="!hideContract">
    <div class="row">
      <div class="col-md-6">
        <common-card>
          <common-card-header>
            <div class="row no-gutters">
              <div class="col">
                <h6>
                  {{'modules.valet.valet_documents.contract' |translate}}
                </h6>
              </div>
            </div>
          </common-card-header>
          <common-card-body>
            <div class="pb-1">
              {{'modules.valet.valet_documents.contract_status' |translate}} :
              <span class="text-success" *ngIf="valet && valet.contractTransactionId && valet.sign === true">
                <strong>{{'modules.valet.valet_documents.sign' |translate}}</strong>
              </span>
              <span class="text-danger" *ngIf="valet && valet.contractTransactionId && valet.sign === false">
                <strong>{{'modules.valet.valet_documents.not_sign' |translate}}</strong>
              </span>
              <span class="text-warning" *ngIf="valet && valet.contractTransactionId && valet.sign === null">
                <strong>{{'modules.valet.valet_documents.awaiting_sign' |translate}}</strong>
              </span>
              <span *ngIf="valet && !valet.contractTransactionId">
                {{'modules.valet.valet_documents.awaiting_contract_generation' |translate}}
              </span>
              <span *ngIf="documents?.contract">
                - <a [href]="documents.contract.url" target="_blank">
                {{'modules.valet.valet_documents.access_contract' |translate}}
              </a>
              </span>
            </div>
            <button *ngIf="viewAs === USER_ROLE.ADMIN"
                    type="button"
                    nbButton
                    size="small"
                    [disabled]="!valet || this.generateContractLoading"
                    [nbSpinner]="generateContractLoading"
                    (click)="generateContract()">
              {{getGenerateContractButtonText()}}
            </button>
          </common-card-body>
        </common-card>
      </div>
      <div class="col-md-6">
        <common-card>
          <common-card-header>
            <div class="row no-gutters">
              <div class="col">
                <h6>
                  {{'modules.valet.valet_documents.activity' |translate}}
                </h6>
              </div>
            </div>
          </common-card-header>
          <common-card-body>
            <div class="pb-4">
              {{'modules.valet.valet_documents.activity_status' |translate}}:
              <span class="text-success" *ngIf="valet && valet.active">
                {{'modules.valet.valet_documents.active' |translate}}
              </span>
              <span class="text-danger" *ngIf="valet && !valet.active">
                {{'modules.valet.valet_documents.not_active' |translate}}
              </span>
            </div>
          </common-card-body>
        </common-card>
      </div>
      <div class="col-md-6"
           *ngIf="viewAs === USER_ROLE.ADMIN">
        <pv-admin-valet-form-document
          [viewAs]="viewAs"
          [cardHeader]="'modules.valet.valet_documents.contract' | translate"
          [documentType]="'contract'"
          [document]="documents ? documents.contract : null"
          [isSubmitting]="documents ? documents.contract?.isSubmitting: true"
          (documentChanged)="documentUpdateHandler($event)"
        >
        </pv-admin-valet-form-document>
      </div>
    </div>
  </div>
  <div *ngIf="viewAs === USER_ROLE.ADMIN && idCheckStatus?.siretStatus === 'INVALID'" class="col-4 mt-3">
    <common-card>
      <common-card-header>
        <h6>{{'modules.valet.valet_documents.siret.header' | translate}}</h6>
      </common-card-header>
      <common-card-body>
        <div>{{'modules.valet.valet_documents.siret.siret' | translate}} {{valet.siret}}</div>
        <div>{{'modules.valet.valet_documents.siret.error_message' | translate}} {{idCheckStatus.siretErrorMessage}}</div>
        <div class="mt-2 d-flex justify-content-center">
          <button type="button"
                  nbButton
                  status="success"
                  size="small"
                  (click)="validateSiret()">
            {{'modules.valet.valet_documents.siret.validate' | translate}}
          </button>
        </div>
      </common-card-body>
    </common-card>
  </div>
</div>
